
body, .modalbg {
  
  --s: 30px; /* control the size */
  --c1: hsl(280, 60%, 98%);
  --c2: #e4dae9;
  
  --_s:37.5% 12.5% at 62.5%;
  --_g:34% 99%,#0000 101%;
  --g1:radial-gradient(var(--_s) 100%,#0000 32%,var(--c1) var(--_g));
  --g2:radial-gradient(var(--_s) 0   ,#0000 32%,var(--c1) var(--_g));
  --g3:radial-gradient(var(--_s) 100%,#0000 32%,var(--c2) var(--_g));
  --g4:radial-gradient(var(--_s) 0   ,#0000 32%,var(--c2) var(--_g));
  background:
    var(--g1),
    var(--g2) 0                calc(3*var(--s)),
    var(--g3) var(--s)         calc(3*var(--s)),
    var(--g4) var(--s)         calc(6*var(--s)),
    var(--g1) calc(2*var(--s)) calc(6*var(--s)),
    var(--g2) calc(2*var(--s)) calc(9*var(--s)),
    var(--g3) calc(3*var(--s)) calc(9*var(--s)),
    var(--g4) calc(3*var(--s)) 0,
    repeating-linear-gradient(var(--c1) 0 25%,var(--c2) 0 50%);
  background-size: calc(4*var(--s)) calc(12*var(--s));
}

.site-header {
  background-color: #381c3ddf;
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  
}
.site-header a {
  color: #f1f1f1;
  transition: color .15s ease-in-out;
}
.site-header a:hover {
  color: #ffffff;
  text-decoration: none;
}
.carousel-item {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; 
}

.btn1 {
  margin-top: 25px;
}


.carousel-image {
  height: auto;
  object-fit: contain;
}
@media screen and (max-width: 1024px) {
  .carousel-image {
    width: 90%;
  }
}


.sketchy {
  border: 3px solid #333333;
  border-radius: 2% 6% 5% 4% / 1% 1% 2% 4%;
  
  &::before {
      content: '';
      border: 2px solid #353535;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0) scale(1.015) rotate(0.5deg);
      border-radius: 1% 1% 2% 4% / 2% 6% 5% 4%;
  }
}


@media only screen and (max-width: 768px) {
  /* Adjust the height for mobile phones */
  .carousel-item {
    height: 60vh;
  }
  .carousel-image {
    width: 100%;
  }
}

.image-container {
  transition: transform 0.3s ease;
}

.image-container:hover {
  transform: scale(1.1) rotate(1deg);
}

.col-container {
  border: solid #381c3ddf;
  border-width: 3px 4px 3px 5px;
  border-radius:95% 4% 92% 5%/4% 95% 6% 95%;
}

.text-shadow {
  text-shadow: 5px 3px 5px rgba(0, 0, 0, 0.733);
}


.glow-on-hover {
  
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}

.glow-on-hover:before {
  content: '';
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
  position: absolute;
  top: -2px;
  left:-2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  border-radius: 2% 6% 5% 4% / 1% 1% 2% 4%;
}

.glow-on-hover:active {
  color: #381c3ddf
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #381c3ddf;
  left: 0;
  top: 0;
  border-radius: 2% 6% 5% 4% / 1% 1% 2% 4%;
}

@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}

.image-container2 {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 500px;
  overflow: hidden;
}

.image-container2 img {
  max-width: 100%;
  height: auto;
}

.logoimg1 {
  width: 100px;
  position: absolute;
  top: 0px;
  left: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.566);

}

@media only screen and (max-width: 600px) {
  .logoimg1 {
    width: 52px;
    top: 0px;
    left: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}
.fade-enter {
  opacity: 0;
  transform: translateY(-20px);
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 500ms, transform 500ms;
}

.fade-exit {
  opacity: 1;
  transform: translateY(0);
}

.fade-exit-active {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 500ms, transform 500ms;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.card-cover {
  animation: fadeIn 1.5s ease;
}

@keyframes fadeIn2 {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.card-cover2 {
  animation: fadeIn2 1.5s ease;
}

@keyframes fadeIn3 {
  0% {
    transform: translateX(0px) rotate(0deg);
  }
  50% {
    transform: translateX(0) rotate(5deg);
  }
  100% {
    transform: translateX(0) rotate(0deg);
  }
}

.card-cover3:hover {
  cursor: pointer;
  animation: fadeIn3 0.2s ease;
}

